import { NEW_SEARCH_CONFIG } from 'shared/experiments/consts'
import { getExperiment } from 'shared/experiments/utils/experiments'
import store from 'shared/store'
import { ARIA_HIDDEN, OVERLAY_SELECTOR } from 'views/assets/scripts/consts'
import bindDummyHeader from 'views/assets/scripts/mobileHeaderDummySearchBox'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import {
  SELECTOR_DESKTOP_CONTAINER,
  SELECTOR_DESKTOP_FORM,
  SELECTOR_DESKTOP_INPUT,
  SELECTOR_DESKTOP_PQSL_WRAPPER,
  SELECTOR_DUMMY_SEARCH_BOX,
  SELECTOR_DUMMY_SEARCH_BOX_INPUT,
  SELECTOR_MOBILE_CONTAINER,
  SELECTOR_MOBILE_INPUT,
  SELECTOR_MOBILE_SEARCH_ICON,
} from './consts'

export default () => {
  const OVERLAY_DELAY = 100
  const TMP_INPUT_TOP_OFFSET = 7

  const desktopOverlay = document.querySelector<HTMLElement>(OVERLAY_SELECTOR)
  const pqslWrapper = document.querySelector<HTMLElement>(
    SELECTOR_DESKTOP_PQSL_WRAPPER
  )

  const desktopInputs = document.querySelectorAll<HTMLInputElement>(
    SELECTOR_DESKTOP_INPUT
  )
  const desktopSearchForms = document.querySelectorAll<HTMLInputElement>(
    SELECTOR_DESKTOP_FORM
  )
  const mobileInput = document.querySelector<HTMLInputElement>(
    SELECTOR_MOBILE_INPUT
  )
  const mobileDummySearchBoxInput = document.querySelector<HTMLInputElement>(
    SELECTOR_DUMMY_SEARCH_BOX_INPUT
  )
  const mobileDummySearchBox = document.querySelector<HTMLElement>(
    SELECTOR_DUMMY_SEARCH_BOX
  )
  const mobileSearchIcon = document.querySelector<HTMLElement>(
    SELECTOR_MOBILE_SEARCH_ICON
  )
  const mobileSearchTriggers = [mobileDummySearchBox, mobileSearchIcon].filter(
    Boolean
  ) as HTMLElement[]
  const mobileSearchInputs = [mobileDummySearchBoxInput, mobileInput].filter(
    Boolean
  ) as HTMLInputElement[]

  let isLoadedDesktop = false
  let desktopInput: HTMLInputElement

  const showDesktopOverlay = (event: Event | { target: HTMLInputElement }) => {
    desktopInput = <HTMLInputElement>event.target

    toggleBoolStringAttr(desktopOverlay!, ARIA_HIDDEN, false)
    toggleBoolStringAttr(pqslWrapper!, ARIA_HIDDEN, false)
    toggleBoolStringAttr(
      desktopInput.closest(SELECTOR_DESKTOP_CONTAINER)!,
      'aria-focused',
      true
    )
  }

  const focusAndOpenMobileKeyboard = (input: HTMLInputElement) => {
    if (!input) {
      return
    }

    // Align temp input approx. to be where the original input is
    const tempInput = document.createElement('input')
    tempInput.style.position = 'absolute'
    tempInput.style.top = `${input.offsetTop + TMP_INPUT_TOP_OFFSET}px`
    tempInput.style.left = `${input.offsetLeft}px`
    tempInput.style.height = '0'
    tempInput.style.opacity = '0'

    document.body.appendChild(tempInput)
    tempInput.focus()

    // The keyboard is open. Now do a delayed focus on the target input
    setTimeout(() => {
      input.focus()
      input.click()
      // Remove the temp input
      document.body.removeChild(tempInput)
    }, OVERLAY_DELAY)
  }

  const hideDesktopOverlay = () => {
    if (isLoadedDesktop) {
      // SearchBoxDesktop has its own handler
      desktopInput.removeEventListener('blur', hideDesktopOverlay)
      return
    }

    toggleBoolStringAttr(desktopOverlay!, ARIA_HIDDEN, true)
    toggleBoolStringAttr(
      desktopInput.closest(SELECTOR_DESKTOP_CONTAINER)!,
      'aria-focused',
      false
    )
  }

  const initSearchAutocomplete = async ({
    input,
    container,
    isMobile,
  }: {
    input: HTMLInputElement | null
    container: HTMLElement | null
    isMobile: boolean
  }) => {
    if (!container || !input) {
      return
    }

    const {
      publicConfig: { locale },
      pageProperties: { pageType, experiments },
    } = store.getPublicRuntimeConfig()
    const i18nPromise = loadCatalogs(locale)
    const { isEnabled } = getExperiment(NEW_SEARCH_CONFIG, experiments)

    const { default: hydrateSearchBoxComponent } = await import(
      /* webpackChunkName: "SearchBoxContainerWithHydration", webpackPrefetch: true */
      `./SearchBoxContainerWithHydration`
    )

    hydrateSearchBoxComponent({
      container,
      i18n: await i18nPromise,
      isMobile,
      query: input.value,
      pageType,
      isNewConfigEnabled: isEnabled,
    })

    isLoadedDesktop = !isMobile
  }

  const loadSearchAutocompleteDesktop = async (event: Event) => {
    showDesktopOverlay(event)

    await initSearchAutocomplete({
      container: desktopInput.closest(SELECTOR_DESKTOP_CONTAINER),
      isMobile: false,
      input: desktopInput,
    })
    showDesktopOverlay({ target: desktopInput })

    // Handles mismatches between style classes in SSR and hydrated layout
    if (desktopInput.value) {
      pqslWrapper?.classList.add('u-hidden')
    }

    desktopInput.removeEventListener('focus', loadSearchAutocompleteDesktop)
    desktopInput.addEventListener('focus', showDesktopOverlay)
  }

  const loadSearchAutocompleteMobile = () => {
    void initSearchAutocomplete({
      container: document.querySelector(SELECTOR_MOBILE_CONTAINER),
      isMobile: true,
      input: mobileInput,
    })

    mobileSearchTriggers.forEach(trigger => {
      trigger.removeEventListener('click', loadSearchAutocompleteMobile)
    })
  }

  const syncMobileSearchInputs = (event: Event) => {
    const target = <HTMLInputElement>event.target
    const { value } = target

    mobileSearchInputs.forEach(input => {
      input!.value = value
    })
  }

  const bindDesktopEvents = () => {
    desktopSearchForms?.forEach(form => {
      form.addEventListener('submit', event => {
        const input: HTMLInputElement | null = form.querySelector('input')
        if (input && !input.value?.length) {
          event.preventDefault()
          input.dispatchEvent(new Event('focus'))
        }
      })
    })

    desktopInputs.forEach(input => {
      input.addEventListener('focus', loadSearchAutocompleteDesktop)
      input.addEventListener('blur', hideDesktopOverlay)
    })
  }

  const bindMobileEvents = () => {
    mobileSearchTriggers.forEach((trigger: HTMLElement) => {
      trigger.addEventListener('click', loadSearchAutocompleteMobile)
      trigger.addEventListener('click', () =>
        focusAndOpenMobileKeyboard(mobileInput!)
      )
    })

    mobileSearchInputs.forEach(input => {
      input.addEventListener('search', syncMobileSearchInputs)
    })

    bindDummyHeader()
  }

  const triggerUpdate = () => {
    const { activeElement } = document
    if (!activeElement) {
      return
    }

    desktopInputs.forEach(input => {
      if (input === activeElement) {
        activeElement.dispatchEvent(new Event('focus'))
        activeElement.dispatchEvent(new Event('input'))
      }
    })

    if (mobileInput === activeElement) {
      activeElement.dispatchEvent(new Event('click'))
    }
  }

  const init = () => {
    const hasMobileInput = Boolean(
      mobileSearchInputs.length || mobileSearchTriggers.length
    )
    const hasDesktopInput = Boolean(desktopInputs.length)

    if (hasMobileInput) {
      bindMobileEvents()
    }
    if (hasDesktopInput) {
      bindDesktopEvents()
    }
    if (hasMobileInput || hasDesktopInput) {
      triggerUpdate()
    }
  }

  init()
}
